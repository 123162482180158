import {addOrEdit} from "@/api/erp/CommonInfoApi";

/**
 * 通用信息model
 */
class CommonInfoModel {
    // 新增或修改
    static async addOrEdit(info) {
        let [data] = await addOrEdit(info);
        return data;
    }
}

export {CommonInfoModel}
