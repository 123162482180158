<template>
  <div class="page-container">
    <!--顶部大图-->
    <div class="top-box flex flex-center" :style='"background-image: url("+pcConfig.img_banner_big+")"'>
      <div class="img-middle" :style='"background-image: url("+pcConfig.img_banner_middle+")"'></div>
    </div>
    <!--实验框-->
    <div class="common-box experiment">
      <div class="title">3D/VR实验</div>
      <div class="experiment-container">
        <div class="list">
          <div class="li">
            <div class="sub-list flex flex-start flex-wrap">
              <div class="sub-li" v-for="(experimentItem,experimentIndex) in commonConfig.experimentList">
                <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                     v-if="experimentItem.product_iconLeft">
                <div class="bg">
                  <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'">
                </div>
                <div class="info">
                  <img class="avatar" :src="experimentItem.product_icon" alt="">
                  <div class="flex flex-dr flex-center">
                    <div class="title">{{ experimentItem.name }}</div>
                    <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                    <!--最多3行 每行18字 第三行超出省略号显示-->
                    <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                    <div class="buttons">
                      <a class="button" href="javascript:void(0)"
                         @click="ExperimentMethods().clickViewExperiment(experimentItem,experimentIndex)">查看详情</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="more-box" @click="clickMoreExperimentBtn"
                   :style='"background-image: url("+pcConfig.img_pc_more_experiment+")"'></div>
            </div>
          </div>
        </div>
      </div>

      <!--产品介绍弹窗-->
      <div v-if="experimentDialog" class="dialog-container flex flex-dr flex-center experiment-dialog">
        <div class="mask" @click="ExperimentMethods().closeExperimentDialog()"></div>
        <div class="dialog-box">
          <div class="bg"></div>
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon"
               @click="ExperimentMethods().closeExperimentDialog()">
          <div class="info-box">
            <div class="header-box flex flex-start">
              <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
              <div class="name-box">
                <div class="name">{{ experimentInfo.experiment.name }}</div>
                <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
              </div>
              <div>
                <div class="enter-btn" v-if="!experimentDialogEnterBtn"
                     @click="ExperimentMethods().clickEnterBtn()"></div>
              </div>
            </div>
            <div class="description-box">
              <div class="title">实验简介：</div>
              <div class="content">
                <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
              </div>
            </div>
            <div class="view-box flex flex-between">
              <div class="video-box" v-if="experimentInfo.experiment.product_video">
                <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                       webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                       class="video1 video"
                       draggable="true"></video>
                <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                     @click="e=>ExperimentMethods().playVideo(e)">
              </div>
              <el-image v-if="!experimentInfo.experiment.product_video" class="video-box-no" fit="cover"
                        :src="experimentInfo.experiment.product_info_img+experimentDialogWaterMark"
                        :preview-src-list="experimentDialogPicList"></el-image>
              <div class="img-box flex flex-around flex-wrap">
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_1+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_2+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_3+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_4+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--视频大窗播放-->
      <div v-if="experimentVideoDialog" class="dialog-container flex flex-dr flex-center experiment-video-dialog">
        <div class="mask" @click="experimentVideoDialog=false"></div>
        <div class="dialog-box">
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon" @click="experimentVideoDialog=false">
          <!--          <img src="@/assets/icon/video-view-btn.png" alt="查看详情" class="view-icon">-->
          <video controls id="v2" autoplay loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                 webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                 class="video2 video"
                 draggable="true"></video>
        </div>
      </div>
    </div>
    <!--vr框-->
    <div class="common-box vr">
      <div class="title">数字展馆</div>
      <div class="sub-list flex flex-start flex-wrap">
        <div class="sub-li" v-for="(vrItem,vrIndex) in commonConfig.vrList"
             :style="`background-image:url(${vrItem.product_bg});`">
          <div class="title">{{ vrItem.name }}</div>
          <div class="info flex flex-dr flex-center" @click="VrMethods().clickOne(vrItem)">
            <img class="icon" src="@/assets/product/glass.png" alt="">
            <div class="series-name"></div>
            <div class="name">{{ vrItem.name }}</div>
            <div class="des word-hr" v-html="vrItem.description"></div>
          </div>
        </div>
        <div class="more-box" @click="clickMoreVrBtn"
             :style='"background-image: url("+pcConfig.img_pc_more_vr+")"'></div>
      </div>
    </div>
    <!--单位框-->
    <div class="organizer-box flex flex-dr flex-center">
      <div class="title">主办单位</div>
      <div class="list">
        <span>{{ commonConfig.organizerList.split('\n')[0] }}、</span>
        <span>{{ commonConfig.organizerList.split('\n')[1] }}</span>
        <span>{{ commonConfig.organizerList.split('\n')[2] }}</span>
      </div>
      <div class="title">协办单位</div>
      <div class="list">
        <span
            v-for="(item,index) in commonConfig.co_organizerList.split('\n')">{{
            item
          }}{{ index !== commonConfig.co_organizerList.split('\n').length - 1 ? '、' : '' }}</span>
      </div>
      <div class="title">技术支持单位</div>
      <div class="list">
        <span
            v-for="(item,index) in commonConfig.tech_organizerList.split('\n')">{{
            item
          }}{{ index !== commonConfig.tech_organizerList.split('\n').length - 1 ? '、' : '' }}</span>
      </div>
    </div>
    <!--申请填写弹窗-->
    <div class="input-box">
      <el-dialog
          title="填写申请信息，免费获取实验"
          :visible.sync="inputInfo.dialog"
          :close-on-click-modal="false"
          width="520px"
          center>
        <div>
          <el-form label-width="100px" ref="inputInfoForm" :model="inputInfo.form" :rules="inputInfo.formRules">
            <el-form-item label="姓 名:" prop="name">
              <el-input v-model.trim="inputInfo.form.name" placeholder="请输入姓名"
                        onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;">
              </el-input>
            </el-form-item>
            <el-form-item label="学 校:" prop="schoolName">
              <el-input v-model.trim="inputInfo.form.schoolName" placeholder="请输入学校名称"
                        onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;">
              </el-input>
            </el-form-item>
            <el-form-item label="职 务:" prop="duty">
              <el-input v-model.trim="inputInfo.form.duty" placeholder="请输入您的职务"
                        onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;">
              </el-input>
            </el-form-item>
            <el-form-item label="联系电话:" prop="phone">
              <el-input v-model.trim="inputInfo.form.phone" placeholder="请输入联系方式"
                        onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;">
              </el-input>
            </el-form-item>
            <el-form-item label="实验人数:" prop="number">
              <el-input v-model.trim="inputInfo.form.number" placeholder="请输入人数"
                        onKeypress="javascript:if(event.keyCode == 32 || event.keyCode == 43)event.returnValue = false;">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="default"
                   @click="inputInfo.dialog=false">取 消</el-button>
        <el-button type="danger" @click="InputMethods().clickSureBtn()">提交，免费获取实验</el-button>
      </span>
      </el-dialog>
    </div>
    <!--下载弹窗-->
    <!--    <div class="download-box">-->
    <!--      <el-dialog-->
    <!--          :visible.sync="downloadInfo.dialog"-->
    <!--          :close-on-click-modal="false"-->
    <!--          width="520px"-->
    <!--          center>-->
    <!--        <div class="flex flex-center flex-dr download-box">-->
    <!--          <div class="title">实验安装包下载中.....</div>-->
    <!--          <img :src="experimentInfo.experiment.product_icon" alt="">-->
    <!--          <div class="name">{{ experimentInfo.experiment.name }}</div>-->
    <!--        </div>-->
    <!--        <span slot="footer" class="dialog-footer">-->
    <!--        <el-button type="danger" @click="downloadInfo.dialog=false">知道了</el-button>-->
    <!--      </span>-->
    <!--      </el-dialog>-->
    <!--    </div>-->
    <div class="download-box">
      <el-dialog
          :visible.sync="downloadInfo.dialog"
          :close-on-click-modal="false"
          width="520px"
          center>
        <div class="flex flex-center flex-dr download-box">
          <div class="title">申请提交成功</div>
          <div class="sub-title">工作人员将在1个工作日内与您取得联系，并发送安装包，请稍后。</div>
          <img :src="experimentInfo.experiment.product_icon" alt="">
          <div class="name">{{ experimentInfo.experiment.name }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="downloadInfo.dialog=false">知道了</el-button>
      </span>
      </el-dialog>
    </div>
    <footer-54></footer-54>
  </div>
</template>

<script>
import {ConfigModel} from "@/model/erp/ConfigModel";
import $ from "jquery";
import {regCommonCheck} from "@/utils/common";
import {CommonInfoModel} from "@/model/erp/CommonInfoModel";
import {msg_err} from "@/utils/ele_component";
import footer54 from "./footer"

export default {
  name: "activity54Study",
  components:{footer54},
  data() {
    // 校检电话
    const validatePhone = (rule, value, callback) => {
      // 联系方式验证
      let checkMobile = regCommonCheck(2, value)
      let checkPhone = regCommonCheck(4, value)
      if (!checkMobile && !checkPhone) {
        callback(new Error("请输入手机号或座机号，座机号不要输入横线"))
        return;
      }
      callback()
    }
    // 校检实验人数
    const validateNumber = (rule, value, callback) => {
      let checkNumber = regCommonCheck(1, value)
      if (!checkNumber) {
        callback(new Error("实验人数需填写正整数"))
        return;
      }
      callback()
    }
    return {
      commonConfig: {
        experimentList: [{}, {}],
        vrList: [],
        organizerList: "",
        co_organizerList: "",
        tech_organizerList: ""
      },
      pcConfig: {
        img_banner_big: "",
        img_banner_middle: "",
        img_pc_more_experiment: "",
        img_pc_more_vr: ""
      },
      experimentInfo: {
        experiment: {},
        list: []
      },
      experimentDialogWaterMark: "",
      experimentDialog: false,
      experimentVideoDialog: false,
      experimentDialogPicList: [],
      experimentDialogEnterBtn: false,
      inputInfo: {
        dialog: false,
        experimentIndex: 0,
        form: {
          name: "",
          schoolName: "",
          duty: "",
          phone: "",
          number: ""
        },
        checkSuccess: false,
        // 输入检测
        formRules: {
          'name': {required: true, trigger: 'blur', message: "请输入姓名"},
          'schoolName': {required: true, trigger: 'blur', message: "请输入学校名称"},
          'duty': {required: true, trigger: 'blur', message: "请输入您的职务"},
          'phone': {required: true, trigger: 'blur', validator: validatePhone},
          'number': {required: true, trigger: 'blur', validator: validateNumber}
        }
      },
      downloadInfo: {
        dialog: false,
      }
    }
  },
  async mounted() {
    // 隐藏顶部
    $("#header-container").hide()
    // 隐藏底部
    $("#footer-container").hide()
    // 获取配置
    let activityConfig = await ConfigModel.getActivityConfig("54study");
    this.commonConfig = activityConfig.commonConfig
    this.pcConfig = activityConfig.pcConfig
  },
  methods: {
    ExperimentMethods() {
      let $this = this;
      return {
        // 点击查看详情按钮
        clickViewExperiment(experiment, index) {
          $this.experimentDialog = true
          document.body.style.overflow = 'hidden'
          experiment.index = index
          $this.inputInfo.experimentIndex = index
          $this.experimentDialogEnterBtn = false
          $this.$set($this.experimentInfo, "experiment", experiment);
          $this.experimentDialogPicList = [
            experiment.product_info_img_1 + $this.experimentDialogWaterMark,
            experiment.product_info_img_2 + $this.experimentDialogWaterMark,
            experiment.product_info_img_3 + $this.experimentDialogWaterMark,
            experiment.product_info_img_4 + $this.experimentDialogWaterMark,
          ]
          if (!experiment.product_video && experiment.product_info_img) {
            $this.experimentDialogPicList.push(experiment.product_info_img + $this.experimentDialogWaterMark)
          }
          setTimeout(() => {
            $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg + $this.experimentDialogWaterMark})`)
          }, 100)
        },
        // 关闭实验弹窗
        closeExperimentDialog() {
          $this.experimentDialog = false
          document.body.style.overflow = ''
        },
        // 点击立即体验按钮
        clickEnterBtn(index) {
          if (sessionStorage.getItem("hasInputActivity54Study") === "true") {
            this.closeExperimentDialog()
            $this.downloadInfo.dialog = true
          } else {
            document.body.style.overflow = ''
            $this.experimentDialog = false
            $this.inputInfo.dialog = true
            $this.inputInfo.experimentIndex = index
          }
        },
        // 播放视频
        playVideo(e) {
          // this.videoPlay = true
          // document.querySelector("#v1").play()
          $this.experimentVideoDialog = true
        }
      }
    },
    VrMethods() {
      let $this = this;
      return {
        // 点击一个
        clickOne(vr) {
          window.open(vr.link)
        },
      }
    },
    clickMoreExperimentBtn() {
      window.location.href = "/product?hash=experiment"
    },
    clickMoreVrBtn() {
      window.location.href = "/product?hash=vr"
    },
    InputMethods() {
      let $this = this;
      return {
        // 点击提交按钮
        async clickSureBtn() {
          $this.$refs['inputInfoForm'].validate(async validate => {
            if (validate) {
              let result = await CommonInfoModel.addOrEdit({
                type: "54studyApplyInfo",
                info: $this.inputInfo.form
              }).catch(res => {
                msg_err("提交失败,请稍后再试")
              })
              if (result.code === "000000") {
                // 标记已经申请过
                sessionStorage.setItem("hasInputActivity54Study", "true")
                $this.downloadInfo.dialog = true
                $this.inputInfo.dialog = false
                // window.open( $this.experimentInfo.experiment.downloadLink)
                // 生成下载链接 并下载
                // const link = document.createElement('a')
                // link.style.display = 'none'
                // link.href = $this.experimentInfo.experiment.downloadLink
                // link.setAttribute('download', $this.experimentInfo.experiment.name + ".zip")
                // document.body.appendChild(link)
                // link.click()
              } else {
                msg_err("提交失败,请稍后再试")
              }
            }
          })
        }
      }
    }
  }
}
</script>
<style>
#header-container {
  background-color: rgba(0, 0, 0, 0.4);
}

#header-container .navs a {
  color: #fff !important;
  opacity: 0.6;
}

#header-container .navs a:hover {
  opacity: 1;
}

#header-container img.black {
  display: none;
}

#header-container .product-navs .list {
  display: none;
}

body {
  background-color: #eceef5;
}
</style>
<style scoped lang="less">
.top-box {
  background-size: cover;
  background-position: 50% 50%;
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: 693px;

  .img-middle {
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    width: 1150px;
    height: 693px;
  }
}

.common-box {
  width: 1278px;
  background: #FFFFFF;
  box-shadow: 1px 8px 10px 0px rgba(154, 165, 195, 0.18), 1px 1px 0px 0px #FFFFFF;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 30px;

  > .title {
    background-image: url("http://resouce.cdzyhd.com/6afb0df8-6aa6-479e-8729-0d9be063fc57.png");
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    width: 469px;
    height: 58px;
    line-height: 58px;
    border-radius: 20px;
    margin: 0 auto;
  }

  .more-box {
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    cursor: pointer;
  }
}

.experiment {

  .sub-list {
    margin-top: 30px;

    .sub-li {
      margin-bottom: 30px;
      margin-right: 40px;
      position: relative;

      &:nth-child(4n+4) {
        margin-right: 0px;
      }

      &:hover {
        .bg {
          img {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }

        .info {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .avatar {
            margin-top: -30px;
            opacity: 0;
          }


          .des-main {
            opacity: 0;
          }

          .title {
            margin-top: -30px;
            margin-bottom: 0px;

          }

          .des {
            display: block;
            height: 65px;
          }

          .buttons {

            display: block;
          }
        }

      }


      img.left-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -10px;
        left: -10px;
        z-index: 10;
      }

      .bg {
        z-index: 1;
        width: 380px;
        height: 240px;
        overflow: hidden;
        position: relative;

        img {
          width: 380px;
          height: 240px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);

        }
      }

      .info {
        position: relative; // 解决margin-top负数 和同级div的z-index问题
        padding: 25px;
        height: 200px;
        z-index: 2;
        width: 345px;
        margin: 0 auto;
        margin-top: -60px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-bottom: 3px solid transparent;
        transition: all 0.2s linear;
        overflow: hidden;

        .avatar {
          display: inline-block;
          width: 60px;
          height: 60px;
          position: relative;
          margin-top: 0px;
          transition: all 0.2s linear;
          border-radius: 4px;
          box-shadow: 2px 2px 5px 1px #dedede;
        }

        .title {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 10px;
          transition: all 0.2s linear;
        }

        .des-main {
          transition: all 0.2s linear;
          color: #666;
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .des {
          font-weight: 300;
          font-size: 14px;
          display: none;
          color: #666;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          max-width: 300px;
        }

        .buttons {
          display: none;
          text-align: center;
          margin-top: 12px;
        }

        .button {
          width: 100px;
          line-height: 28px;
          border: 1px solid #d8d8d8;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;
          text-decoration: none;
          padding: 5px 20px;

          &:hover {
          }
        }

      }
    }
  }

  .more-box {
    margin-top: -28px;
    width: 377px;
    height: 380px;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  .experiment-dialog {
    border-radius: 4px;
    z-index: 2000; // 解决被遮挡问题
    .dialog-box {
      border-radius: 4px;
    }

    .bg {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      width: 100%;
      height: 330px;
      background-size: cover;
      background-position: 50% 50%;
    }

    .close-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .info-box {
      padding: 0px 80px;
    }

    .header-box {
      position: relative;
      margin-top: -115px;

      .avatar {
        width: 130px;
        height: 130px;
        border-radius: 4px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        margin-right: 20px;
      }

      .name-box {
        .name {
          font-size: 33px;
          font-weight: bold;
          line-height: 44px;
          color: #FFFFFF;
          text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          opacity: 0.9;
        }

        .series {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: #FFFFFF;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
          opacity: 0.9;
          margin-bottom: 8px;
        }
      }

      .enter-btn {
        margin-left: 300px;
        width: 97px;
        height: 29px;
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        background-image: url("../../../assets/product/dialog/enter-btn.png");
        cursor: pointer;
      }

      .view-now-btn {
        display: inline-block;
        padding: 10px 30px;
        background-color: #ff3829;
        color: #fff;
        font-size: 14px;
        margin-left: 200px;
        border-radius: 20px;
      }

    }

    .description-box {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
      margin-top: 20px;

      .title {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #333;
      }

      .content {
        margin-top: 5px;
        font-size: 14px;
        text-indent: 2em;
        font-weight: 400;
        line-height: 19px;
        color: #333333;
      }
    }

    .view-box {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 35px;

      .video-box {
        width: 337px;
        height: 223px;
        position: relative;

        video {
          position: absolute;
          left: 0;
          top: 0;
          width: 337px;
          height: 223px;
          object-fit: fill;
          z-index: 1;
        }

        .play-btn {
          position: absolute;
          // 上下左右居中
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 54px;
          height: 54px;
          cursor: pointer;
          z-index: 2;
        }
      }

      .video-box-no {
        width: 337px;
        height: 223px;
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
      }

      .img-box {
        width: 695px;

        .img {
          width: 334px;
          height: 106px;
          cursor: pointer;

          &:nth-child(1), &:nth-child(2) {
            margin-bottom: 11px;
          }
        }
      }
    }
  }

  .experiment-video-dialog {
    z-index: 2001;

    .dialog-box {
      width: 1200px;
      height: 720px;
    }

    .close-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 3;
    }

    .view-icon {
      display: inline-block;
      width: 100px;
      height: 30px;
      position: absolute;
      bottom: 80px;
      right: 25px;
      cursor: pointer;
      z-index: 3;
    }

    video {
      width: 1200px;
      height: 720px;
      object-fit: fill;
    }
  }
}

.vr {
  .sub-list {
    margin-top: 30px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 380px);
    grid-gap: 10px;

    .sub-li {
      margin-bottom: 20px;
      width: 380px;
      height: 380px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      opacity: 1;
      position: relative;
      overflow: hidden;

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: #000;
        opacity: 0.8;
        text-align: center;
        font-size: 16px;
        line-height: 60px;
        font-weight: 400;
        color: #fff;
        transition: all 0.4s ease-out;
      }

      .info {
        cursor: pointer;
        position: absolute;
        width: 100%;
        top: 400px;
        left: 0;
        background-color: #000;
        height: 400px;
        color: #fff;
        padding: 0 20px;
        transition: all 0.3s ease;

        .icon {
          width: 32px;
          height: 32px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .series-name {
          margin-bottom: 15px;
        }

        .name {
          font-size: 19px;
          margin-bottom: 15px;
          cursor: pointer;
        }

        .des {
          font-size: 15px;
          text-align: center;
          width: 100%;
        }
      }


      &:hover {
        .title {
          bottom: -60px;
        }

        .info {
          top: 0px;
          opacity: 0.8;
        }
      }
    }
  }

  .more-box {
    width: 380px;
    height: 380px;
    margin-top: -20px;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }
}

.organizer-box {
  margin-top: 60px;
  width: 100%;
  height: 320px;
  background: radial-gradient(circle, #8B1912 0%, #DD5E2B 100%);
  padding-top: 20px;

  .title {
    width: 179px;
    height: 27px;
    background: #F6E2C7;
    box-shadow: 0px 4px 11px 0px rgba(76, 89, 144, 0.35);
    border-radius: 14px;
    font-size: 18px;
    font-weight: bold;
    color: #792102;
    line-height: 27px;
    text-align: center;
    margin-bottom: 18px;
  }

  .list {
    font-size: 18px;
    font-weight: normal;
    color: #FFFFFF;
    margin-bottom: 18px;
  }
}

.download-box {
  .title {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .sub-title {
    font-size: 15px;
    margin-bottom: 30px;
  }

  img {
    width: 120px;
    height: 120px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
  }

  .name {
    font-size: 16px;
  }
}
</style>
